<!-- 积分账单 -->
<template>
	<div :class="themeClass" class="myPointsContent">
		<div>
			<div class="flex-row-align-center">
				<div style="width: 100%; height: 20px;" class="font-weight700  billtext flex-row font-size18">
					<span>积分账单</span>
					<!-- <el-carousel height="100%" direction="vertical" :autoplay="true" :interval="3000" style="width: 800px; margin-left: 20px; color: #f88607;">
					    <el-carousel-item v-for="(item, index) in pointMessage" :key="index">
					      <div class="medium" style="font-size: 14px; font-weight: 400">
							<span style="font-weight: 700; margin: 10px;">{{ item.fShopName }}</span>
							<span>{{ item.fMessagePoint }}</span>
						  </div>
					    </el-carousel-item>
					  </el-carousel> -->
				</div>
			</div>
			<div class="flex-row-align-center date">
        <div class="choose-date">
          <!-- 新时间组件 -->
          <Timequery @change="getTimeall"/>
          <!-- 新时间组件 -->
        </div>
				<!-- <span :class="activeName=='yearMonth'?'activeClass':''" style="width: 50px;">
					年月
				</span>
				<el-select  v-model="balance" placeholder="请选择年月" style="width: 140px;margin-right:10px;" @change="monthChange">
					<el-option v-for="(item,index) in balanceOptions" :key="index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<div class="tabs">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane class="pointer-color-theme" label="昨天" name="yesterday"></el-tab-pane>
						<el-tab-pane class="pointer-color-theme" label="今天" name="today"></el-tab-pane>
					</el-tabs>
				</div>
				<div class="inputBor margin-r-10">
					<el-date-picker v-model="searchDate[0]" type="date" placeholder="请选择开始日期"></el-date-picker>
					<span>至</span>
					<el-date-picker v-model="searchDate[1]" type="date" placeholder="请选择结束日期"></el-date-picker>
				</div> -->
				<div class="allOrder-btn font-size13">
					<div class="background-color-theme pointer zad" @click="aaa()">查询</div>
					<div type="primary" class="background-color-theme ad pointer" @click="ExportEXcel" >账单下载</div>
				</div>
			</div>

			<!-- tab切换 -->
			<div class="tabTitleRow pointer">
				<div v-for="(item, index) in tabData" :key="index" :class="item.checked ? 'checkTitleSty' : ''" @click="selecttab(index)">{{ item.title }}</div>
			</div>
			<!-- 统计列表 -->
			<div v-if="listShow == 0">	
				<div class="tabOne flex-row-space-between" v-for="(item, index) of billDetailsData" :key="index" v-loading="listLoading">
					<div class="left flex-row-start-center">
						<div class="flex-row-start-center">
							<img :src="item.fShopLogoPath" alt="" class="imgbox">
							<div style="width:150px">
								<div class="tit hide-text">{{item.fShopName}}</div>
							</div>
						</div>
						
						<div style="margin-left: 14px;">
							<div style="text-align: center;margin-bottom: 4px;" class=""><img src="../../../assets/u256.png" style="width: 15px; height:15px;" alt=""> 余额</div>
							<div style="text-align: center;font-size: 15px;color:#E20A30;font-weight:700;">{{item.fEndBalance}}</div>
						</div>
					</div>
					<div class="right flex-space-around">
						<div class="flex-colum-between">
							<div style="color:#8F949E"><i class="iconfont icon-jifen" style="margin-right:5px;font-size:20px;"></i>已获取</div>
							<div style='text-align: center;font-size:15px;color:#1B2541;font-weight:700;'>{{item.fAddData}}</div>
						</div>
						<div class="flex-colum-between">
							<div style="color:#8F949E"><i class="iconfont icon-xiadan1" style="margin-right:5px;font-size:20px;"></i>已使用</div>
							<div style='text-align: center;font-size:15px;color:#1B2541;font-weight:700;'>{{item.fAddData}}</div>
						</div>
						<div class="flex-colum-between">
							<div style="color:#8F949E"><i class="iconfont icon-jijiangkaishi" style="margin-right:5px;font-size:20px;"></i>即将失效 <span style="color:#FF0000;font-size:12px;">{{item.expirationDate}}</span></div>
							<div style='text-align: center;font-size:15px;color:#1B2541;font-weight:700;'>{{item.fOpeningBalance}}</div>
						</div>
						<div class="flex-colum-between">
							<div style="color:#8F949E"><i class="iconfont icon-tishi4" style="margin-right:5px;font-size:20px;"></i>已失效</div>
							<div style='text-align: center;font-size:15px;color:#8F949E;font-weight:700;'>{{item.fAddData}}</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 账单报表 -->
			<div v-else v-loading="tableLoading">
				<div class="tabTwo">
					<el-table :data="tableData" :header-cell-style="{ 'text-align': 'center'}" tooltip-effect="dark" border style="width: 100%;">
						<el-table-column prop="fShopName" fixed show-overflow-tooltip  label="店铺" width="240" align="left">
						</el-table-column>
						<el-table-column prop="fSourceBillNumber" fixed label="单据号" width="140" align="center"></el-table-column>
						<el-table-column prop="fChangeCapital" label="积分" width="80" align="right"></el-table-column>
						<el-table-column prop="fCreateTime" label="积分时间" width="160" align="center"></el-table-column>
						<el-table-column prop="fRemark" show-overflow-tooltip width="auto" label="备注" align="left"></el-table-column>
					</el-table>
				</div>
				<div class="flex-row-center-flex-end margin-t-20 margin-b-30">
					<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="page"
					 :pagesize="pagesize" :total="total"></pagination>
				</div>
			</div>

			<div class="flex-row-center-center" v-if="billDetailsData.length == 0 && listShow == 0">
				<svg-icon icon-class="NoRightsAndInterests" />
			</div>
		</div>
	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex';
  import Timequery from "@/components/Timequery.vue"
	import pagination from '@/components/pagination/pagination.vue';
	import Marquee from '@/components/Marquee'
	export default {
		components: {
			pagination,
			Marquee,
      Timequery
		},
		data() {
			return {
				listLoading:false,
				tableLoading:false,
				balance:'',
				balanceOptions: [],
				startMouth:'',
				endMouth:'',
				activeName: 'today',
				// searchDate: [new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 90)), new Date()],
				searchDate: [new Date(), new Date()],
				defaultTime: '', //时间默认值
				value: '',
				listShow: 0,
				maxResultCount: 15,
				tableData: [],
				columnList:[],
				page:1,
				total: 0, //总条数
				pagesize: 15, //每页显示条目个数
				currentPage: 1, //当前页
				
				
				tabData: [{
						title: '统计报表',
						type: 1,
						checked: true
					},
					{
						title: '账单列表',
						type: 2,
						checked: false
					}
				],
				tablebles: [{
						label: "店铺",
						width: "276",
						prop: "fShopName",
						align: "center",
						fixed: true
					},
					{
						label: "积分时间",
						width: "100",
						prop: "fCreateTime",
						align: "center",
						fixed: true
					},
					{
						label: "积分",
						width: "100",
						prop: "fChangeCapital",
						align: "center",
						fixed: true
					},
					{
						label: "相关票据号",
						width: "276",
						prop: "fSourceBillNumber",
						align: "center",
						fixed: true
					},
					{
						label: "备注",
						width: "100",
						prop: "fRemark",
						align: "center",
						fixed: true
					}
				],
				billDetailsData: [],
				pointMessage: []
			};
		},
		created() {
			this.getdayValue();
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			this.acquireMonth();
			// this.pointBillGetList();
			// let that = this;
			// that.getdatatime();
			this.acquireDate();
		},
		methods: {
      getTimeall (val) {
        console.log(val)
        this.searchDate[0] = val[0]
			  this.searchDate[1] = val[1]
        this.aaa()
      },
			// 积分到期提醒
			getmessage() {
				this.ApiRequestPost('api/mall/ebbalance/customer-point/get-my-point-and-message', {
				}).then(
					result => {
						console.log('111111111111',result)
						let list = result.obj
						if (list) {
							list.forEach(item => {
								if (item.fIsMessage) {
									this.pointMessage.push(item)
									this.billDetailsData.forEach(item1 => {
										if (item1.fShopName == item.fShopName) {
											item1.fMessagePoint = item.fMessagePoint
										} 
									})
								}
							})
							console.log(this.billDetailsData)
						}
						this.listLoading=false;
					},
					error => {
						this.listLoading=false;
					}
				);
			},
			//获取当前时间的前12个月
			dateAcquire(){
				    var dataArr = [];
				    var data = new Date();
				    var year = data.getFullYear();
				    data.setMonth(data.getMonth()+1, 1)//获取到当前月份,设置月份
				    for (var i = 0; i < 12; i++) {
				        data.setMonth(data.getMonth() - 1);//每次循环一次 月份值减1
				        var m = data.getMonth() + 1;
				        m = m < 10 ? "0" + m : m;
						dataArr.push({
							value: data.getTime(),
							label: data.getFullYear() + "年" + (m) + "月"
						})
				    }
					this.balanceOptions = JSON.parse(JSON.stringify(dataArr))
			},
			//时间更改
			monthChange(e){
				this.activeName = "yearMonth"
				this.searchDate[0] = this.getMonthFirst(e)
				this.searchDate[1] = this.getMonthLast(e)
				this.pointBillGetList1();
				this.pointBillGetList();
			},
			//格式化时间
			getDateNoDay(time) {
			  if (time == null || time == '') {
			    return '';
			  }
			  let date = new Date(time);
			  let year = date.getFullYear(); //年
			  let month = date.getMonth() + 1; //月
			  let day = date.getDate(); //日
			  month = month > 9 ? month : '0' + month;
			  day = day > 9 ? day : '0' + day;
			  return `${year}年${month}月`;
			},
			//获取某个月的第一天
			getMonthFirst(date){
				const stringDate = new Date(date)
			 	const enddate = new Date(stringDate.getFullYear(), stringDate.getMonth(), 1)
			 	return enddate
			},
			//获取某个月的最后一天
			getMonthLast(date){
				const stringDate = new Date(date)
			 	const enddate = new Date(stringDate.getFullYear(), stringDate.getMonth() + 1, 0)
			 	return enddate
			},
			acquireDate(){
				this.dateAcquire()
			},
			acquireMonth(){
				this.ApiRequestPostNOMess("api/mall/sys/account-date/get-curret-date", {
				}).then(
					(res) => {
					// console.log(res,'89898989898')
					this.startMouth = res.obj.fStartDate
					this.endMouth = res.obj.fEndDate
					},
					(error) => {}
				);
			},
			handleCurrentChange(val) {
				this.page = val;
				this.pointBillGetList1();
			},
			//pageSize（每页个数） 改变时会触发
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
			},
			// 快捷切换时间
			handleClick(tab, event) {
				if (this.searchDate == null) {
					this.searchDate = ['', ''];
				}
				const start = new Date();
				const end = new Date();
				this.balance = "";
				switch (this.activeName) {
					case 'all':
						this.searchDate[0] = '';
						this.searchDate[1] = '';
						break;
					case 'today':
						this.searchDate[0] = start.setTime(start.getTime())
						this.searchDate[1] = end;
						break;
					case 'yesterday':
						this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24));
						this.searchDate[1] = this.searchDate[0];
						break;
					case 'oneMonth':
						this.searchDate[0] = this.startMouth;
						this.searchDate[1] = this.endMouth;
						break;
				}
				this.currentPage = 1;
				if (this.listShow == 1) {
					this.pointBillGetList1();
				} else if (this.listShow == 0) {
					this.pointBillGetList();
				}
			},
			//获取当前时间
			getdayValue() {
				let a = new Date();
				this.defaultTime = this.getDate(a);
			},
			//查询
			aaa() {
				this.pointBillGetList1();
				this.pointBillGetList();
			},
			//tab点击事件
			selecttab(e) {
				for (let item of this.tabData) {
					item.checked = false;
				}
				this.tabData[e].checked = true;
				this.listShow = e;
				if (this.listShow == 1) {
					this.pointBillGetList1();
				} else if (this.listShow == 0) {
					this.pointBillGetList();
				}
			},
			//获取日期的值
			bindChange(e) {
				console.log(e, 'e');
				this.value = e;
				// // console.log('gundong',e)
				this.beginTime = this.getDate(this.value[0]);
				this.endTime = this.getDate(this.value[1]);
			},
			//获取账单明细
			pointBillGetList() {
				this.listLoading=true;
				if (this.searchDate.length > 1) {
					if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
						return this.$message('结束时间不能早于开始时间');
					}
				}
				this.ApiRequestPost('api/mall/ebbalance/customer-point-detail/get-point-details-summery', {
					// filter: '',
					beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
					endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
					// maxResultCount: this.maxResultCount,
					// skipCount: (this.page - 1) * this.maxResultCount,
					// sorting: ''
				}).then(
					result => {
						console.log('获取账单列表',result)
						
						
						this.billDetailsData = result.obj;
						
						this.getmessage()
					},
					error => {
						this.listLoading=false;
					}
				);
			},
			// 获取账单列表
			pointBillGetList1() {
				this.tableLoading=true;
				if (this.searchDate.length > 1) {
					if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
						return this.$message('结束时间不能早于开始时间');
					}
				}
				this.ApiRequestPost('api/mall/ebbalance/customer-point-detail/get-point-details-by-customerid', {
					filter: '',
					beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
					endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
					maxResultCount: this.maxResultCount,
					skipCount: (this.page - 1) * this.maxResultCount,
					sorting: ''
				}).then(
					result => {
						console.log(result, 'result');
						this.tableData = []
						for (let item of result.obj.items) {
							if( item.fCreateTime  != null){
							item.fCreateTime = this.getTime(item.fCreateTime);	
							}
							this.tableData = result.obj.items;
							this.total = result.obj.totalCount
							// item.fModifyTime = this.getTime(item.fModifyTime);
							item.fEndTime = this.getDate(item.fEndTime);
							item.fBeginTime = this.getDate(item.fBeginTime);
						}
						this.$forceUpdate();
						this.tableLoading=false;
					},
					error => {
						this.tableLoading=false;
					}
				);
			},
			// EXcel导出
			ExportEXcel() {
				this.downloadLoading = true;
				console.log('列表数据', this.tablebles);
				this.columnList = [];
				for (var i = 0; i < this.tablebles.length; i++) {
					this.columnList.push({
						Key: this.tablebles[i].prop,
						Value: this.tablebles[i].label
					});
				}
				if (this.searchDate.length > 1) {
					if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
						return this.$message('结束时间不能早于开始时间');
					}
				}
				if (this.tableData.length == 0) {
					return this.$message('导出数据不能为空！');
				}
				this.requestUrl = 'api/mall/ebbalance/customer-point-detail/down-point-detail-by-customerid';
				this.ApiRequestPostNOMess(this.requestUrl, {
					model: this.columnList,
					beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
					endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
					filter: ''
				}).then(
					result => {
						console.log('获取EXcel导出数据', result);
						window.open(result.obj);
						this.downloadLoading = false;
					},
					rej => {
						this.downloadLoading = false;
					}
				);
			}
		}
	};
</script>
<style scoped lang="scss">
.choose-date {
    width: 406px;
    display: flex;
    align-items: center;
  }
	/deep/ .el-input__suffix-inner {
		// line-height: 50px;
		display: none;
	}

	/deep/ .el-tabs__header {
		margin: 0;
	}

	/deep/ .el-tabs__item {
		padding: 0;
		width: 75px;
		height: 32px;
		line-height: 32px;
		text-align: center;
	}

	/deep/ .el-tabs__active-bar {
		display: none;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	.inputBor {
		width: 280px;
		display: flex;
		border: 1px solid #f2f2f2;

		/deep/.el-input__inner {
			border: 0 !important;
			cursor: pointer;
		}

		span {
			line-height: 30px;
		}
	}

	/deep/ .el-input__icon {
		line-height: 22px;
	}

	/deep/ .el-tabs__item.is-active {
		color: #fff;

		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	.top {
		width: 100%;
		height: 32px;

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 240px;
		}

		.search-box {
			width: 270px;
		}

		.search-btn {
			width: 79px;
			height: 32px;
			line-height: 32px;
			background: #f8f7fa;
			border-radius: 4px;
			border: 1px solid #f2f2f2;
		}
	}

	/deep/ .el-input {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-date-editor .el-range__icon {
		height: 32px;
	}

	/deep/ .el-date-editor .el-range-separator {
		height: 32px;
	}

	/deep/ .el-input__suffix-inner {
		line-height: 50px;
	}

	.el-select__caret .el-input__icon .el-icon-arrow-up {
		line-height: 32px;
	}

	::v-deep .el-table .cell {
		white-space: nowrap;
	}

	::v-deep .el-table thead {
		font-size: 13px;
		color: #000000;
		font-weight: 500;
		// text-align: center;
	}

	::v-deep .el-table td,
	::v-deep .el-table th {
		height: 38px;
		padding: 0px 0px;
		// text-align: center;
	}

	::v-deep .is-leaf {
		border-right: 0px solid transparent;
		background-color: #e9f3ff;
	}

	::v-deep .el-table__footer-wrapper tbody td,
	::v-deep .el-table__header-wrapper tbody td {
		height: 38px;
		background-color: #c1cff7;
	}

	.myPointsContent {
		width: 921px;
		min-height: 485px;
		background-color: #ffffff;
		// padding: 10px 10px;
		margin-top: 12px;
		padding: 20px;
	}

	.allOrder-btn {
		display: flex;
		// position: relative;
		// left: 295px;
	}

	.allOrder-btn div:nth-child(1) {
		width: 75px;
		height: 32px;
		margin-right: 5px;
		text-align: center;
		line-height: 32px;
	}

	.allOrder-btn div:nth-child(2) {
		width: 110px;
		height: 32px;
		line-height: 32px;
		text-align: center;
	}

	.block {
		margin-left: 260px;
		margin-right: 10px;
	}

	.red {
		color: #ff0000;
	}

	.black {
		color: #000;
	}

	/* tab切换标题 */
	.tabTitleRow {
		height: 40px;
		background-color: #f8f9fe;
		border: 1px solid #eee;
		margin-top: 13px;
		box-sizing: border-box;
		margin-bottom: 10px;
	}

	::v-deep .el-table__row {
		height: 36px;
	}

	.tabTitleRow div {
		width: 108px;
		display: inline-block;
		height: 40px;
		line-height: 40px;
		color: #666;
		text-align: center;
		box-sizing: border-box;
	}

	.tabTitleRow div:first-child {
		border-left: none !important;
	}

	.tabTitleRow .checkTitleSty {
		@include themify($themes) {
			color: themed('themes_color');
		}

		font-weight: bold;
		background-color: #fff;
		border-left: 1px solid #eee;
		border-right: 1px solid #eee;
	}

	.line-height39 {
		line-height: 39px;
	}

	.billtext {
		line-height: 20px;
		padding-bottom: 10px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.date {
		// position: relative;
		// top: 2px;
		// left: 285px;
	}
	
	.num {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: none;
		text-align: center;
		width: 300px;
		margin-top: 10px;
	}
	.nomessagenum {
		margin-top: 30px;
	}

	.num1 {
		width: 150px;
		float: left;
	}

	.num2 {
		width: 150px;
		float: left;
	}

	.txt {
		width: 280px;
		display: flex;
		justify-content: space-evenly;
		border-bottom: none;
	}

	.tabOne {
		width: 916px;
		height: 89px;
		border: 2px solid #D7D7D7;
		border-radius: 4px;
		padding: 19px 18px;
		margin-bottom: 13px;
		box-sizing: border-box;
		.left {
			heihgt: 100%;
			width: 35%;
			border-right: 1px dashed #D7D7D7;
			.imgbox {
				border-radius: 4px;
				border: 1px solid #D7D7D7;
				width: 35px;
				height: 35px;
				margin-right: 14px;
			}
			.tit {
				font-size: 15px;
				font-weight: 700;
			}
			.endDate {
				width: 110px;
				padding: 0 4px;
				font-size: 14px;
				background: #C6DDFF;
				color: #0173FE;
				display: inline-block;
				height: 24px;
				line-height: 24px;
			}
		}
		.right {
			heihgt: 100%;
			width: 65%;
		}
	}

	.topbgc {
		// width: 917px;
		height: 35px;
		background: #f8f9fe;
		padding: 0 15px;
		margin: 11px auto;
	}

	::v-deep.el-input--suffix .el-input__inner {
		line-height: 32px !important;
		height: 32px !important;
	}

	::v-deep .el-input__icon {
		line-height: 32px !important;
	}

	.billsitems {
		width: 100%;
		height: 64px;
		border-bottom: 1px solid #f2f2f2;
	}

	.allOrder-btn .zad {
		/* position: absolute;
		left: 1110px; */
	}

	.allOrder-btn .ad {
		/* position: absolute;
		left: 1190px; */
	}
	.activeClass{
		width: 50px;
		height: 32px;
		background-color: #0173FE;
		color:white;
		line-height: 32px;
		text-align: center;
	}
</style>
