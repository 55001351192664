<template>  
  <div class="marquee">  
    <div class="marquee-content" :style="{ transform: `translateX(-${scrollAmount}px)` }">  
      {{ text }}  
    </div>  
  </div>  
</template>  
  
<script>  
export default {  
  name: 'Marquee',  
  props: {  
    text: {  
      type: String,  
      required: true  
    },  
    speed: {  
      type: Number,  
      default: 2  
    }  
  },  
  data() {  
    return {  
      scrollAmount: 0  
    }  
  },  
  mounted() {  
    this.startScrolling();  
  },  
  beforeDestroy() {  
    this.stopScrolling();  
  },  
  methods: {  
    startScrolling() {  
      this.interval = setInterval(() => {  
        this.scrollAmount += this.speed;  
        if (this.scrollAmount > this.$el.offsetWidth) {  
          this.scrollAmount = 0;  
        }  
      }, 100);  
    },  
    stopScrolling() {  
      clearInterval(this.interval);  
    }  
  }  
}  
</script>  
  
<style scoped>  
.marquee {  
  overflow: hidden;  
  white-space: nowrap;  
}  
  
.marquee-content {  
  display: inline-block;  
/* padding-left: 100%;  初始时隐藏所有内容 */  
  animation: marquee 15s linear infinite;  
}  
  
@keyframes marquee {  
  0% {  
    transform: translateX(100%);  
  }  
  100% {  
    transform: translateX(-100%);  
  }  
}  
</style>